import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
} from "@coreui/react";
import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import { Invoice } from "../../models/invoice";
import Errors, { getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";
import Client from "../../models/client";
import ClientSelect from "../client/ClientSelect";

interface InvoiceNominationModalProps {
  show: boolean;
  invoice?: Invoice;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InvoiceNominationModal: React.FC<InvoiceNominationModalProps> = ({
  show,
  invoice,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState("");
  const [client, setClient] = useState<Client | null>(null);
  const [errors, setErrors] = useState<Errors>({});

  const message = invoice
    ? `Está seguro de que quiere nominar la factura nº ${invoice.establishmentCode}-${invoice.dispatchPointIdentifier}-${invoice.invoiceNumber}?`
    : "";

  const onNominationClick = useCallback(async () => {
    if (invoice !== undefined && client !== undefined) {
      setSubmitting(true);
      const status = await createItem(`/nominations/create/`, {
        taxPayerId: invoice.taxPayerId,
        taxPayerRuc: invoice.taxPayerRuc,
        taxPayerSocialName: invoice.taxPayerSocialName,
        taxPayerFantasyName: invoice.taxPayerFantasyName,
        stampId: invoice.stampId,
        stampIdentifier: invoice.stampIdentifier,
        stampBeginDate: invoice.stampBeginDate,
        invoiceId: invoice.id,
        cdc: invoice.cdc,
        reason: reason,
        clientId: client?.id,
        clientCode: client?.code,
        clientIsTaxPayer: client?.isTaxPayer,
        clientRuc: client?.ruc,
        clientSocialName: client?.socialName,
        clientFantasyName: client?.fantasyName,
        clientType: client?.type,
        clientCountryCode: client?.countryCode,
        clientHasAddress: client?.hasAddress,
        clientAddress: client?.address,
        clientHouseNumber: client?.houseNumber,
        clientDepartment: client?.department,
        clientDepartmentDescription: client?.departmentDescription,
        clientDistrict: client?.district,
        clientDistrictDescription: client?.districtDescription,
        clientCity: client?.city,
        clientCityDescription: client?.cityDescription,
        clientPhoneNumber: client?.phoneNumber,
        clientEmail: client?.email,
      });

      if (status.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (status.detail !== undefined) {
          message = status.detail;
        }
        if (status.errors !== undefined) {
          setErrors(status.errors);
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, reason]);

  const onReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const onClientChange = (newClient: Client | null) => {
    setClient(newClient);
  };

  return (
    <CModal show={show} className="modal-info" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Nominar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Motivo:
                </CLabel>
              </CCol>
              <CCol>
                <CTextarea
                  placeholder="Motivo"
                  value={reason}
                  onChange={onReasonChange}
                ></CTextarea>
                <FieldErrors
                  errors={getFieldErrors("reason", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Cliente:
                </CLabel>
              </CCol>
              <CCol>
                <CInputGroup>
                  <ClientSelect
                    value={client}
                    onChange={onClientChange}
                  ></ClientSelect>
                </CInputGroup>
                <FieldErrors
                  errors={getFieldErrors("clientId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={submitting || client === null}
          onClick={onNominationClick}
          color="info"
        >
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Nominando..." : "Nominar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default InvoiceNominationModal;
