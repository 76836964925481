import { useSelector } from "react-redux";
import { RootState } from "../../store";
import React, { useEffect, useRef, useState } from "react";

import Datetime from "react-datetime";

import Errors, {
  extractArrayErrors,
  getFieldErrors,
} from "../../models/errors";
import Client, { CLIENT_TYPE_GOVERNMENT } from "../../models/client";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "../utils/messages";
import Establishment from "../../models/establishment";
import {
  ItemRequestStatus,
  createItem,
  getItem,
  updateItem,
} from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import EstablishmentSelect from "../establishments/EstablishmentSelect";
import { FieldErrors } from "../form/FieldErrors";
import ClientSelect from "../client/ClientSelect";
import Loading from "../indicators/Loading";
import ClientUpdateModal from "../client/ClientUpdateModal";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import RemissionNoteItemForm from "./RemissionNoteItemForm";
import { Spinner } from "react-bootstrap";
import { getRoutePath } from "../../routes";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import {
  ASSOCIATED_DOCUMENT_TYPE_CHOICES,
  ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE,
  ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE,
} from "../../models/associated-document";
import { INVOICE_STATUS_APPROVED_BY_SET, Invoice } from "../../models/invoice";
import InvoiceSelect from "../invoice/InvoiceSelect";
import {
  REMISSION_NOTE_REASON_CHOICES,
  REMISSION_NOTE_RESPONSIBLE_CHOICES,
  REMISSION_NOTE_STATUS_APPROVED_BY_SET,
  REMISSION_NOTE_STATUS_UPLOADED_TO_SET,
  RemissionNote,
  RemissionNoteItem,
  newRemissionNote,
  newRemissionNoteItem,
} from "../../models/remission-note";
import {
  TRANSPORT_MODE_CHOICES,
  TRANSPORT_RESPONSIBLE_CHOICES,
  TRANSPORT_TYPE_CHOICES,
  TRANSPORT_VEHICLE_DOCUMENT_TYPE_CHOICES,
} from "../../models/transport";
import Department from "../../models/department";
import District from "../../models/district";
import City from "../../models/city";
import DepartmentSelect from "../location/DepartmentSelect";
import DistrictSelect from "../location/DistrictSelect";
import CitySelect from "../location/CitySelect";
import NotFound from "../indicators/NotFound";
import ServerError from "../indicators/ServerError";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";
import { getClientById } from "../../api/client";
import { getInvoiceById } from "../../api/invoice";
import {
  getCityByCode,
  getDepartmentByCode,
  getDistrictByCode,
} from "../../api/locations";
import { changeTZToUtc } from "../../utils/dates";
import moment from "moment";

const RemissionNoteForm = () => {
  const name = "Agregar Nota de Remisión";

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadError, setLoadError] = useState(false);
  const [loadNotFound, setLoadNotFound] = useState(false);

  const remissionNoteRef = useRef<RemissionNote | null>(
    newRemissionNote(taxPayer.id)
  );
  const [remissionNoteDate, setRemissionNoteDate] = useState<Date | undefined>(
    undefined
  );

  const [associatedDocumentType, setAssociatedDocumentType] = useState<
    string | undefined
  >(undefined);

  const [electronicInvoice, setElectronicInvoice] = useState<Invoice | null>(
    null
  );

  /*const updateremissionNoteRef = (remissionNote: RemissionNote) => {
    remissionNoteRef.current = remissionNote;
  };*/

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [client, setClient] = useState<Client | null>(null);
  const [showAddClient, setShowAddClient] = useState(false);

  const [items, setItems] = useState<RemissionNoteItem[]>([]);

  const [transportDepartureDepartment, setTransportDepartureDepartment] =
    useState<Department | null>(null);
  const [transportDepartureDistrict, setTransportDepartureDistrict] =
    useState<District | null>(null);
  const [transportDepartureCity, setTransportDepartureCity] =
    useState<City | null>(null);

  const [transportArrivalDepartment, setTransportArrivalDepartment] =
    useState<Department | null>(null);
  const [transportArrivalDistrict, setTransportArrivalDistrict] =
    useState<District | null>(null);
  const [transportArrivalCity, setTransportArrivalCity] = useState<City | null>(
    null
  );

  const history = useHistory();

  const getEditingRemissionNoteId = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const editingRemissionNoteid = urlParams.get("id");

    if (editingRemissionNoteid !== null) {
      if (!isNaN(parseInt(editingRemissionNoteid))) {
        return parseInt(editingRemissionNoteid);
      }
    }

    return undefined;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.establishmentId = newEstablishment?.id;
    remissionNoteRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dispatchPointId = newDispatchPoint?.id;
    remissionNoteRef.current.dispatchPointIdentifier =
      newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.stampId = newStamp?.id;
    remissionNoteRef.current.stampIdentifier = newStamp?.identifier;
    remissionNoteRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onClientChange = (newClient: Client | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.clientId = newClient?.id;
    remissionNoteRef.current.clientCode = newClient?.code;
    remissionNoteRef.current.clientIsTaxPayer = newClient?.isTaxPayer;
    remissionNoteRef.current.clientRuc = newClient?.ruc;
    remissionNoteRef.current.clientSocialName = newClient?.socialName;
    remissionNoteRef.current.clientFantasyName = newClient?.fantasyName;
    remissionNoteRef.current.clientType = newClient?.type;
    remissionNoteRef.current.clientCountryCode = newClient?.countryCode;
    remissionNoteRef.current.clientHasAddress = newClient?.hasAddress;
    remissionNoteRef.current.clientAddress = newClient?.address;
    remissionNoteRef.current.clientHouseNumber = newClient?.houseNumber;
    remissionNoteRef.current.clientDepartment = newClient?.department;
    remissionNoteRef.current.clientDepartmentDescription =
      newClient?.departmentDescription;
    remissionNoteRef.current.clientDistrict = newClient?.district;
    remissionNoteRef.current.clientDistrictDescription =
      newClient?.districtDescription;
    remissionNoteRef.current.clientCity = newClient?.city;
    remissionNoteRef.current.clientCityDescription = newClient?.cityDescription;
    remissionNoteRef.current.clientPhoneNumber = newClient?.phoneNumber;
    remissionNoteRef.current.clientEmail = newClient?.email;

    setClient(newClient);
  };

  const onRemissionNoteDateChange = (value: string | moment.Moment) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    const newDate = value as moment.Moment;

    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const remissionNoteDate = new Date((value as moment.Moment).toISOString());
    setRemissionNoteDate(remissionNoteDate);

    remissionNoteRef.current.remissionNoteDate =
      remissionNoteDate.toISOString();
  };

  const onDncpContractCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dncpContractCode = e.target.value;
  };

  const onDncpContractModalityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dncpContractModality = e.target.value;
  };

  const onDncpContractEntityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dncpContractEntity = e.target.value;
  };

  const onDncpContractYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dncpContractYear = e.target.value;
  };

  const onDncpContractSequenceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dncpContractSequence = e.target.value;
  };

  const onDncpContractDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.dncpContractDate = e.target.value;
  };

  const onAssociatedDocumentTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    const newAssociatedDocumentType = e.target.value;

    remissionNoteRef.current.associatedDocumentType = newAssociatedDocumentType;

    if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE
    ) {
      remissionNoteRef.current.associatedDocumentPrintedType = undefined;
      remissionNoteRef.current.associatedDocumentStampIdentifier = undefined;
      remissionNoteRef.current.associatedDocumentEstablishmentCode = undefined;
      remissionNoteRef.current.associatedDocumentDispatchPointCode = undefined;
      remissionNoteRef.current.associatedDocumentNumber = undefined;
      remissionNoteRef.current.associatedDocumentDate = undefined;
    } else if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE
    ) {
      remissionNoteRef.current.associatedDocumentCdc = undefined;
      remissionNoteRef.current.invoiceId = undefined;
      remissionNoteRef.current.associatedDocumentPrintedType =
        ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;
      setElectronicInvoice(null);
    }

    setAssociatedDocumentType(e.target.value);
  };

  const onAssociatedDocumentStampIdentifierChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.associatedDocumentStampIdentifier = e.target.value;
  };

  const onAssociatedDocumentEstablishmentCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.associatedDocumentEstablishmentCode =
      e.target.value;
  };

  const onAssociatedDocumentDispatchPointCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.associatedDocumentDispatchPointCode =
      e.target.value;
  };

  const onAssociatedDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.associatedDocumentNumber = e.target.value;
  };

  const onAssociatedDocumentDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.associatedDocumentDate = e.target.value;
  };

  const onElectronicInvoiceChange = (newElectronicInvoice: Invoice | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.invoiceId = newElectronicInvoice?.id;
    remissionNoteRef.current.associatedDocumentCdc = newElectronicInvoice?.cdc;

    setElectronicInvoice(newElectronicInvoice);
  };

  const onReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.reasonCode = parseInt(e.target.value);
    } else {
      remissionNoteRef.current.reasonCode = undefined;
    }
  };

  const onResponsibleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.responsibleCode = parseInt(e.target.value);
    } else {
      remissionNoteRef.current.responsibleCode = undefined;
    }
  };

  const onEstimadedKilometersChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.estimatedKilometers = parseInt(e.target.value);
    } else {
      remissionNoteRef.current.estimatedKilometers = undefined;
    }
  };

  const onTransportModeCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.transportModeCode = parseInt(e.target.value);
    } else {
      remissionNoteRef.current.transportModeCode = undefined;
    }
  };

  const onTransportEstimatedStartDate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportEstimatedStartDate = e.target.value;
  };

  const onTransportEstimatedEndDate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportEstimatedEndDate = e.target.value;
  };

  const onTransportTypeCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.transportTypeCode = parseInt(e.target.value);
    } else {
      remissionNoteRef.current.transportTypeCode = undefined;
    }
  };

  const onTransportResponsibleCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.transportResponsibleCode = parseInt(
        e.target.value
      );
    } else {
      remissionNoteRef.current.transportResponsibleCode = undefined;
    }
  };

  const onTransportDepartureAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }
    remissionNoteRef.current.transportDepartureAddress = e.target.value;
  };

  const onTransportDepartureHouseNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }
    if (isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.transportDepartureHouseNumber = undefined;
    } else {
      remissionNoteRef.current.transportDepartureHouseNumber = parseInt(
        e.target.value
      );
    }
  };

  const onTransportDepartureDepartmentChange = (
    newDepartment: Department | null
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportDepartureDepartment = newDepartment?.code;
    remissionNoteRef.current.transportDepartureDepartmentDescription =
      newDepartment?.description;
    remissionNoteRef.current.transportDepartureDistrict = undefined;
    remissionNoteRef.current.transportDepartureDistrictDescription = undefined;

    setTransportDepartureDepartment(newDepartment);
    setTransportDepartureDistrict(null);
    setTransportDepartureCity(null);
  };

  const onTransportDepartureDistrictChange = (newDistrict: District | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportDepartureDistrict = newDistrict?.code;
    remissionNoteRef.current.transportDepartureDistrictDescription =
      newDistrict?.description;
    remissionNoteRef.current.transportDepartureCity = undefined;
    remissionNoteRef.current.transportDepartureCityDescription = undefined;

    setTransportDepartureDistrict(newDistrict);
    setTransportDepartureCity(null);
  };

  const onTransportDepartureCityChange = (newCity: City | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportDepartureCity = newCity?.code;
    remissionNoteRef.current.transportDepartureCityDescription =
      newCity?.description;

    setTransportDepartureCity(newCity);
  };

  const onTransportArrivalAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }
    remissionNoteRef.current.transportArrivalAddress = e.target.value;
  };

  const onTransportArrivalHouseNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }
    if (isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.transportArrivalHouseNumber = undefined;
    } else {
      remissionNoteRef.current.transportArrivalHouseNumber = parseInt(
        e.target.value
      );
    }
  };

  const onTransportArrivalDepartmentChange = (
    newDepartment: Department | null
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportArrivalDepartment = newDepartment?.code;
    remissionNoteRef.current.transportArrivalDepartmentDescription =
      newDepartment?.description;
    remissionNoteRef.current.transportArrivalDistrict = undefined;
    remissionNoteRef.current.transportArrivalDistrictDescription = undefined;

    setTransportArrivalDepartment(newDepartment);
    setTransportArrivalDistrict(null);
    setTransportArrivalCity(null);
  };

  const onTransportArrivalDistrictChange = (newDistrict: District | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportArrivalDistrict = newDistrict?.code;
    remissionNoteRef.current.transportArrivalDistrictDescription =
      newDistrict?.description;
    remissionNoteRef.current.transportArrivalCity = undefined;
    remissionNoteRef.current.transportArrivalCityDescription = undefined;

    setTransportArrivalDistrict(newDistrict);
    setTransportArrivalCity(null);
  };

  const onTransportArrivalCityChange = (newCity: City | null) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportArrivalCity = newCity?.code;
    remissionNoteRef.current.transportArrivalCityDescription =
      newCity?.description;

    setTransportArrivalCity(newCity);
  };

  const ontransportVehicleTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }
    remissionNoteRef.current.transportVehicleType = e.target.value;
  };

  const onTransportVehicleBrandChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportVehicleBrand = e.target.value;
  };

  const onTransportVehicleDocumentTypeCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }
    if (isNaN(parseInt(e.target.value))) {
      remissionNoteRef.current.transportVehicleDocumentTypeCode = undefined;
    } else {
      remissionNoteRef.current.transportVehicleDocumentTypeCode = parseInt(
        e.target.value
      );
    }
  };

  const onTransportVehicleDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportVehicleDocumentNumber = e.target.value;
  };

  const onTransportVehicleRegistrationNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.transportVehicleRegistrationNumber =
      e.target.value;
  };

  const onCarrierRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.carrierRuc = e.target.value;
  };

  const onCarrierSocialNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.carrierSocialName = e.target.value;
  };

  const onCarrierAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.carrierAddress = e.target.value;
  };

  const onDriverDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.driverDocumentNumber = e.target.value;
  };

  const onDriverNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.driverName = e.target.value;
  };

  const onDriverAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current.driverAddress = e.target.value;
  };

  const onAddClientClick = () => {
    setShowAddClient(true);
  };

  const onAddClientSuccess = (newClient?: Client) => {
    let message = "Cliente agregado correctamente";
    onClientChange(newClient ? newClient : null);
    if (newClient) {
      message = `Cliente ${newClient.socialName} agregado correctamente`;
    }
    successAlert(message);
    setShowAddClient(false);
  };

  const onAddClientCancel = () => {
    setShowAddClient(false);
  };

  const onSave = async () => {
    if (remissionNoteRef.current === null) {
      return;
    }

    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<RemissionNote>>;
    let successMessage = "Nota de Remisión agregada exitósamente";

    const remissionNote = remissionNoteRef.current;
    remissionNote.taxPayerRuc = taxPayer.ruc;
    remissionNote.taxPayerSocialName = taxPayer.socialName;
    remissionNote.taxPayerFantasyName = taxPayer.fantasyName;

    // TODO: make this dynamic
    remissionNote.carrierIsTaxPayer = true;

    remissionNote.hasAssociatedDocument =
      remissionNote.associatedDocumentType !== undefined;

    if (remissionNote.id === undefined) {
      requestPromise = createItem<RemissionNote>(
        "/remission_notes/create/",
        remissionNote
      );
    } else {
      requestPromise = updateItem<RemissionNote>(
        `/remission_notes/${remissionNote.id}/`,
        remissionNote
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";

      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      successAlert(successMessage);
      history.push(getRoutePath("remissionNotes"));
    }
  };

  const loadInitialRemissionNoteById =
    async (): Promise<RemissionNote | null> => {
      const editingId = getEditingRemissionNoteId();

      if (editingId === undefined) {
        return null;
      }

      let requestStatus = await getItem<RemissionNote>(
        `/remission_notes/${editingId}/`
      );

      if (requestStatus.status !== SUCCESS) {
        if (requestStatus.status === NOT_FOUND) {
          setLoadNotFound(true);
        } else {
          setLoadError(true);
        }

        return null;
      }

      if (requestStatus.data === undefined || requestStatus.data === null) {
        setLoadError(true);
        return null;
      }

      return requestStatus.data!;
    };

  const loadInitialData = async () => {
    setLoading(true);
    const remissionNote = await loadInitialRemissionNoteById();

    if (remissionNote === null) {
      setLoading(false);
      return;
    }

    if (
      remissionNote.status === REMISSION_NOTE_STATUS_UPLOADED_TO_SET ||
      remissionNote.status === REMISSION_NOTE_STATUS_APPROVED_BY_SET
    ) {
      setLoading(false);
      setLoadError(true);
    }

    const stampPromise = getStampById(
      remissionNote.stampId,
      remissionNote.taxPayerId
    );
    const establishmentPromise = getEstablishmentById(
      remissionNote.establishmentId,
      remissionNote.taxPayerId
    );
    const dispatchPointPromise = getDispatchPointById(
      remissionNote.dispatchPointId,
      remissionNote.taxPayerId
    );
    const clientPromise = getClientById(
      remissionNote.clientId,
      remissionNote.taxPayerId
    );

    let invoicePromise: Promise<Invoice | null> | undefined = undefined;

    if (remissionNote.invoiceId !== undefined) {
      invoicePromise = getInvoiceById(
        remissionNote.invoiceId,
        remissionNote.taxPayerId
      );
    }

    const transportDepartureDepartmentPromise = getDepartmentByCode(
      remissionNote.transportDepartureDepartment
    );
    const transportDepartureDistrictPromise = getDistrictByCode(
      remissionNote.transportDepartureDistrict
    );
    const transportDepartureCityPromise = getCityByCode(
      remissionNote.transportDepartureCity
    );

    const transportArrivalDepartmentPromise = getDepartmentByCode(
      remissionNote.transportArrivalDepartment
    );
    const transportArrivalDistrictPromise = getDistrictByCode(
      remissionNote.transportArrivalDistrict
    );
    const transportArrivalCityPromise = getCityByCode(
      remissionNote.transportArrivalCity
    );

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;
    const client = await clientPromise;
    const transportDepartureDepartment =
      await transportDepartureDepartmentPromise;
    const transportDepartureDistrict = await transportDepartureDistrictPromise;
    const transportDepartureCity = await transportDepartureCityPromise;
    const transportArrivalDepartment = await transportArrivalDepartmentPromise;
    const transportArrivalDistrict = await transportArrivalDistrictPromise;
    const transportArrivalCity = await transportArrivalCityPromise;

    if (remissionNote.invoiceId !== undefined && invoicePromise !== undefined) {
      const invoice = await invoicePromise;
      setElectronicInvoice(invoice);
    }

    remissionNoteRef.current = remissionNote;

    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);
    setClient(client);
    setTransportDepartureDepartment(transportDepartureDepartment);
    setTransportDepartureDistrict(transportDepartureDistrict);
    setTransportDepartureCity(transportDepartureCity);
    setTransportArrivalDepartment(transportArrivalDepartment);
    setTransportArrivalDistrict(transportArrivalDistrict);
    setTransportArrivalCity(transportArrivalCity);
    setAssociatedDocumentType(remissionNote.associatedDocumentType);
    onItemsChange(remissionNote.items);

    setLoading(false);
  };

  useEffect(() => {
    const newRemissionNoteDate = changeTZToUtc(new Date());
    if (remissionNoteRef.current !== null) {
      remissionNoteRef.current.remissionNoteDate =
        newRemissionNoteDate.toISOString();
      setRemissionNoteDate(newRemissionNoteDate);
    }
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddItemClick = () => {
    if (remissionNoteRef.current === null) {
      return;
    }

    const newItems = [
      ...remissionNoteRef.current.items,
      newRemissionNoteItem(),
    ];
    onItemsChange(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onItemDelete = (index: number) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    const newItems = [...remissionNoteRef.current.items];
    newItems.splice(index, 1);

    onItemsChange(newItems);
  };

  const onItemChange = (index: number, item: RemissionNoteItem) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    const newItems = [...remissionNoteRef.current.items];
    newItems[index] = item;
    onItemsChange(newItems);
  };

  const onItemsChange = (newItems: RemissionNoteItem[]) => {
    if (remissionNoteRef.current === null) {
      return;
    }

    remissionNoteRef.current = {
      ...remissionNoteRef.current,
      items: newItems,
    };

    setItems(newItems);
  };

  const showElectronicInvoiceSelect =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;

  const showPrintedInvoiceFields =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;

  const itemErrors = extractArrayErrors("items", errors);

  if (loading) {
    return <Loading />;
  }

  if (loadNotFound) {
    return <NotFound />;
  }

  if (loadError) {
    return <ServerError />;
  }

  return (
    <>
      {!loading ? (
        <CCard>
          <CCardHeader>{name}</CCardHeader>
          <CCardBody>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Timbrado:
                  </CLabel>
                </CCol>
                <CCol>
                  <StampSelect onChange={onStampChange} value={stamp} />
                  <FieldErrors
                    errors={getFieldErrors("stampId", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Establecimiento:
                  </CLabel>
                </CCol>
                <CCol>
                  <EstablishmentSelect
                    onChange={onEstablishmentChange}
                    value={establishment}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("establishmentId", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Punto de Expedición:
                  </CLabel>
                </CCol>
                <CCol>
                  <DispatchPointSelect
                    onChange={onDispatchPointChange}
                    value={dispatchPoint}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("dispatchPointId", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Cliente:
                  </CLabel>
                </CCol>
                <CCol>
                  <CInputGroup>
                    <ClientSelect
                      value={client}
                      onChange={onClientChange}
                    ></ClientSelect>
                    <CInputGroupAppend>
                      <CButton
                        className="btn btn-primary"
                        // Prevent button on top
                        style={{ zIndex: 0 }}
                        onClick={onAddClientClick}
                      >
                        <i className="fa fa-plus"></i>
                      </CButton>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <FieldErrors
                    errors={getFieldErrors("clientId", errors) as string[]}
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors("clientHasAddress", errors) as string[]
                    }
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "clientDepartmentDescription",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "clientDistrictDescription",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "clientCityDescription",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors("clientHouseNumber", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Fecha de emisión:
                  </CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onRemissionNoteDateChange}
                    value={remissionNoteDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                  <FieldErrors
                    errors={
                      getFieldErrors("remissionNoteDate", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            {client !== null && client.type === CLIENT_TYPE_GOVERNMENT ? (
              <>
                <CRow>
                  <CCol>
                    <h4>Datos de Contratación DNCP</h4>
                  </CCol>
                </CRow>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Código de
                        Contratación:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        placeholder="Ingrese el Código de Contratación"
                        onChange={onDncpContractCodeChange}
                        defaultValue={
                          remissionNoteRef.current !== null &&
                          remissionNoteRef.current.dncpContractCode
                            ? remissionNoteRef.current.dncpContractCode
                            : ""
                        }
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors("dncpContractCode", errors) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Modalidad de
                        Contratación:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        placeholder="Ingrese la modalidad de Contratación"
                        onChange={onDncpContractModalityChange}
                        defaultValue={
                          remissionNoteRef.current !== null &&
                          remissionNoteRef.current.dncpContractModality
                            ? remissionNoteRef.current.dncpContractModality
                            : ""
                        }
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "dncpContractModality",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Entidad de
                        Contratación:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        placeholder="Ingrese la entidad de Contratación"
                        onChange={onDncpContractEntityChange}
                        defaultValue={
                          remissionNoteRef.current !== null &&
                          remissionNoteRef.current.dncpContractEntity
                            ? remissionNoteRef.current.dncpContractEntity
                            : ""
                        }
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "dncpContractEntity",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Año de
                        Contratación:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        placeholder="Ingrese el año de Contratación"
                        onChange={onDncpContractYearChange}
                        defaultValue={
                          remissionNoteRef.current !== null &&
                          remissionNoteRef.current.dncpContractYear
                            ? remissionNoteRef.current.dncpContractYear
                            : ""
                        }
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors("dncpContractYear", errors) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Secuencia de
                        Contratación:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        placeholder="Ingrese la secuencia de Contratación"
                        onChange={onDncpContractSequenceChange}
                        defaultValue={
                          remissionNoteRef.current !== null &&
                          remissionNoteRef.current.dncpContractSequence
                            ? remissionNoteRef.current.dncpContractSequence
                            : ""
                        }
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "dncpContractSequence",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Fecha de
                        Contratación:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="date"
                        placeholder="Ingrese la fecha de Contratación"
                        onChange={onDncpContractDateChange}
                        defaultValue={
                          remissionNoteRef.current !== null &&
                          remissionNoteRef.current.dncpContractDate
                            ? remissionNoteRef.current.dncpContractDate
                            : ""
                        }
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors("dncpContractDate", errors) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </>
            ) : (
              <></>
            )}
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Tipo Documento Asociado:</CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.associatedDocumentType
                        : undefined
                    }
                    onChange={onAssociatedDocumentTypeChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(ASSOCIATED_DOCUMENT_TYPE_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "associatedDocumentType",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            {showElectronicInvoiceSelect ? (
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Factura
                      Electrónica:
                    </CLabel>
                  </CCol>
                  <CCol>
                    <InvoiceSelect
                      onChange={onElectronicInvoiceChange}
                      value={electronicInvoice}
                      status={INVOICE_STATUS_APPROVED_BY_SET}
                    />
                    <FieldErrors
                      errors={getFieldErrors("invoiceId", errors) as string[]}
                    ></FieldErrors>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentCdc",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            ) : (
              <></>
            )}
            {showPrintedInvoiceFields ? (
              <>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Timbrado:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          remissionNoteRef.current
                            ? remissionNoteRef.current
                                .associatedDocumentStampIdentifier
                            : undefined
                        }
                        onChange={onAssociatedDocumentStampIdentifierChange}
                        placeholder="Timbrado"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentStampIdentifier",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Establecimiento:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          remissionNoteRef.current
                            ? remissionNoteRef.current
                                .associatedDocumentEstablishmentCode
                            : undefined
                        }
                        onChange={onAssociatedDocumentEstablishmentCodeChange}
                        placeholder="Cod"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentEstablishmentCode",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Punto Expedición:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          remissionNoteRef.current
                            ? remissionNoteRef.current
                                .associatedDocumentDispatchPointCode
                            : undefined
                        }
                        onChange={onAssociatedDocumentDispatchPointCodeChange}
                        placeholder="Cod"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentDispatchPointCode",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Nro. Factura:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          remissionNoteRef.current
                            ? remissionNoteRef.current.associatedDocumentNumber
                            : undefined
                        }
                        onChange={onAssociatedDocumentNumberChange}
                        placeholder="Nro"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentNumber",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Fecha Factura:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="date"
                        defaultValue={
                          remissionNoteRef.current
                            ? remissionNoteRef.current.associatedDocumentDate
                            : undefined
                        }
                        onChange={onAssociatedDocumentDateChange}
                        placeholder="Fecha"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentDate",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </>
            ) : (
              <></>
            )}
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Motivo:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.reasonCode
                        : undefined
                    }
                    onChange={onReasonChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(REMISSION_NOTE_REASON_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                  <FieldErrors
                    errors={getFieldErrors("reasonCode", errors) as string[]}
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Responsable:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.responsibleCode
                        : undefined
                    }
                    onChange={onResponsibleChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(
                      REMISSION_NOTE_RESPONSIBLE_CHOICES.entries()
                    ).map((entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    })}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors("responsibleCode", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> KMs estimados:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="number"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.estimatedKilometers
                        : undefined
                    }
                    onChange={onEstimadedKilometersChange}
                    placeholder="KMs estimados"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("estimatedKilometers", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h4>Datos de Transporte</h4>
              </CCol>
            </CRow>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Fecha de Inicio de
                    Transporte:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="date"
                    placeholder="Ingrese la Fecha de Inicio del Transporte"
                    onChange={onTransportEstimatedStartDate}
                    defaultValue={
                      remissionNoteRef.current !== null
                        ? remissionNoteRef.current.transportEstimatedStartDate
                        : ""
                    }
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportEstimatedStartDate",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Fecha de Fin de
                    Transporte:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="date"
                    placeholder="Ingrese la Fecha de Fin del Transporte"
                    onChange={onTransportEstimatedEndDate}
                    defaultValue={
                      remissionNoteRef.current !== null
                        ? remissionNoteRef.current.transportEstimatedEndDate
                        : ""
                    }
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportEstimatedEndDate",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Tipo de Transporte:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportTypeCode
                        : undefined
                    }
                    onChange={onTransportTypeCodeChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(TRANSPORT_TYPE_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors("transportTypeCode", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Modalidad de
                    Transporte:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportModeCode
                        : undefined
                    }
                    onChange={onTransportModeCodeChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(TRANSPORT_MODE_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors("transportModeCode", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Responsable de
                    Transporte:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportResponsibleCode
                        : undefined
                    }
                    onChange={onTransportResponsibleCodeChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(TRANSPORT_RESPONSIBLE_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportResponsibleCode",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h5>
                  <u>Dirección de Salida</u>
                </h5>
              </CCol>
            </CRow>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Departamento
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <DepartmentSelect
                    onChange={onTransportDepartureDepartmentChange}
                    value={transportDepartureDepartment}
                    placeholder="Departamento"
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportDepartureDepartment",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Distrito
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <DistrictSelect
                    onChange={onTransportDepartureDistrictChange}
                    value={transportDepartureDistrict}
                    placeholder="Distrito"
                    departmentValue={
                      transportDepartureDepartment !== null
                        ? transportDepartureDepartment
                        : undefined
                    }
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportDepartureDistrict",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Ciudad
                  </CLabel>
                </CCol>
                <CCol>
                  <CitySelect
                    onChange={onTransportDepartureCityChange}
                    placeholder="Ciudad"
                    value={transportDepartureCity}
                    district={
                      transportDepartureDistrict !== null
                        ? transportDepartureDistrict
                        : undefined
                    }
                    department={
                      transportDepartureDepartment !== null
                        ? transportDepartureDepartment
                        : undefined
                    }
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportDepartureCity",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Dirección
                  </CLabel>
                </CCol>
                <CCol md={6}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportDepartureAddress
                        : ""
                    }
                    onChange={onTransportDepartureAddressChange}
                    placeholder="Dirección"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportDepartureAddress",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Número de casa:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="number"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportDepartureHouseNumber
                        : undefined
                    }
                    onChange={onTransportDepartureHouseNumberChange}
                    placeholder="Número de casa"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportDepartureHouseNumber",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h5>
                  <u>Dirección de Entrega</u>
                </h5>
              </CCol>
            </CRow>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Departamento
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <DepartmentSelect
                    onChange={onTransportArrivalDepartmentChange}
                    value={transportArrivalDepartment}
                    placeholder="Departamento"
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportArrivalDepartment",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Distrito
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <DistrictSelect
                    onChange={onTransportArrivalDistrictChange}
                    value={transportArrivalDistrict}
                    placeholder="Distrito"
                    departmentValue={
                      transportArrivalDepartment !== null
                        ? transportArrivalDepartment
                        : undefined
                    }
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportArrivalDistrict",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Ciudad
                  </CLabel>
                </CCol>
                <CCol>
                  <CitySelect
                    onChange={onTransportArrivalCityChange}
                    placeholder="Ciudad"
                    value={transportArrivalCity}
                    district={
                      transportArrivalDistrict !== null
                        ? transportArrivalDistrict
                        : undefined
                    }
                    department={
                      transportArrivalDepartment !== null
                        ? transportArrivalDepartment
                        : undefined
                    }
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("transportArrivalCity", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Dirección
                  </CLabel>
                </CCol>
                <CCol md={6}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportArrivalAddress
                        : ""
                    }
                    onChange={onTransportArrivalAddressChange}
                    placeholder="Dirección"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportArrivalAddress",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Número de casa:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="number"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportArrivalHouseNumber
                        : undefined
                    }
                    onChange={onTransportArrivalHouseNumberChange}
                    placeholder="Número de casa"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportArrivalHouseNumber",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h5>
                  <u>Vehículo</u>
                </h5>
              </CCol>
            </CRow>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Tipo de Vehículo:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportVehicleType
                        : ""
                    }
                    onChange={ontransportVehicleTypeChange}
                    placeholder="Tipo"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("transportVehicleType", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Marca
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.transportVehicleBrand
                        : ""
                    }
                    onChange={onTransportVehicleBrandChange}
                    placeholder="Marca"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportVehicleBrand",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Tipo de
                    Documentación:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current
                            .transportVehicleDocumentTypeCode
                        : undefined
                    }
                    onChange={onTransportVehicleDocumentTypeCodeChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(
                      TRANSPORT_VEHICLE_DOCUMENT_TYPE_CHOICES.entries()
                    ).map((entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    })}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportVehicleDocumentType",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Documento de Vehículo
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current
                            .transportVehicleDocumentNumber
                        : ""
                    }
                    onChange={onTransportVehicleDocumentNumberChange}
                    placeholder="Documento de Vehículo"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportVehicleDocumentNumber",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Matrícula de Vehículo
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current
                            .transportVehicleRegistrationNumber
                        : ""
                    }
                    onChange={onTransportVehicleRegistrationNumberChange}
                    placeholder="Documento de Vehículo"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "transportVehicleRegistrationNumber",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h5>
                  <u>Datos Transportista</u>
                </h5>
              </CCol>
            </CRow>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> RUC:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.carrierRuc
                        : ""
                    }
                    onChange={onCarrierRucChange}
                    placeholder="RUC"
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("carrierRuc", errors) as string[]}
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Razón Social:
                  </CLabel>
                </CCol>
                <CCol md={6}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.carrierSocialName
                        : ""
                    }
                    onChange={onCarrierSocialNameChange}
                    placeholder="Razón Social"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("carrierSocialName", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Dirección:
                  </CLabel>
                </CCol>
                <CCol md={6}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.carrierAddress
                        : ""
                    }
                    onChange={onCarrierAddressChange}
                    placeholder="Dirección"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("carrierSocialName", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h5>
                  <u>Datos Conductor</u>
                </h5>
              </CCol>
            </CRow>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Nro. Documento:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.driverDocumentNumber
                        : ""
                    }
                    onChange={onDriverDocumentNumberChange}
                    placeholder="Documento"
                  ></CInput>
                  <FieldErrors
                    errors={
                      getFieldErrors("driverDocumentNumber", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Nombre:
                  </CLabel>
                </CCol>
                <CCol md={6}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.driverName
                        : ""
                    }
                    onChange={onDriverNameChange}
                    placeholder="Nombre"
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("driverName", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Dirección:
                  </CLabel>
                </CCol>
                <CCol md={6}>
                  <CInput
                    type="text"
                    defaultValue={
                      remissionNoteRef.current
                        ? remissionNoteRef.current.driverAddress
                        : ""
                    }
                    onChange={onDriverAddressChange}
                    placeholder="Dirección"
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("driverAddress", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h4>Ítems</h4>
              </CCol>
            </CRow>
            {items.map((item, ix) => (
              <CRow key={ix}>
                <CCol>
                  <RemissionNoteItemForm
                    item={item}
                    client={client}
                    onDelete={() => onItemDelete(ix)}
                    onChange={(item) => onItemChange(ix, item)}
                    errors={ix < itemErrors.length ? itemErrors[ix] : {}}
                  />
                </CCol>
              </CRow>
            ))}
            <CRow>
              <CCol>
                <CButton
                  className="btn btn-primary float-right"
                  onClick={onAddItemClick}
                >
                  Agregar Ítem
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CCardFooter>
        </CCard>
      ) : (
        <Loading />
      )}
      <ClientUpdateModal
        show={showAddClient}
        onSuccess={onAddClientSuccess}
        onCancel={onAddClientCancel}
      />
    </>
  );
};

export default RemissionNoteForm;
