import { CPopover } from "@coreui/react";
import {
  INVOICE_STATUS_APPROVED_BY_SET,
  INVOICE_STATUS_CANCELLATION_FAILED,
  INVOICE_STATUS_CANCELLATION_REQUESTED,
  INVOICE_STATUS_CANCELLED,
  INVOICE_STATUS_CHOICES,
  INVOICE_STATUS_ERROR,
  INVOICE_STATUS_NOMINATED,
  INVOICE_STATUS_RESEND_REQUESTED,
  INVOICE_STATUS_SET_UNKNOWN_ERROR,
  INVOICE_STATUS_UPLOADED_TO_SET,
  Invoice,
} from "../../models/invoice";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";

interface InvoiceCrudStatusProps {
  invoice: Invoice;
}

const InvoiceCrudStatus: React.FC<InvoiceCrudStatusProps> = ({ invoice }) => {
  const [currentInvoice, setCurrentInvoice] = useState(invoice);

  useSub(`invoice_${invoice.id}`, (invoice: Invoice) => {
    setCurrentInvoice(invoice);
  });

  useEffect(() => {
    setCurrentInvoice(invoice);
  }, [invoice]);

  let badgeClassName = "badge-info";

  if (currentInvoice.status === INVOICE_STATUS_UPLOADED_TO_SET) {
    badgeClassName = "badge-warning";
  }

  if (currentInvoice.status === INVOICE_STATUS_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentInvoice.status === INVOICE_STATUS_SET_UNKNOWN_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentInvoice.status === INVOICE_STATUS_APPROVED_BY_SET) {
    badgeClassName = "badge-success";
  }

  if (currentInvoice.status === INVOICE_STATUS_CANCELLATION_REQUESTED) {
    badgeClassName = "badge-light";
  }

  if (currentInvoice.status === INVOICE_STATUS_CANCELLED) {
    badgeClassName = "badge-info";
  }

  if (currentInvoice.status === INVOICE_STATUS_CANCELLATION_FAILED) {
    badgeClassName = "badge-danger";
  }

  if (currentInvoice.status === INVOICE_STATUS_RESEND_REQUESTED) {
    badgeClassName = "badge-warning";
  }

  if (currentInvoice.status === INVOICE_STATUS_NOMINATED) {
    badgeClassName = "badge-success";
  }

  return (
    <td className="text-center">
      <span
        className={`badge badge-pill ${badgeClassName}`}
        style={{ fontSize: "14px" }}
      >
        {INVOICE_STATUS_CHOICES.get(currentInvoice.status!)}{" "}
        {currentInvoice.status === INVOICE_STATUS_ERROR ||
        currentInvoice.status === INVOICE_STATUS_CANCELLATION_FAILED ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Código: {currentInvoice.setResponseCode}</li>
                <li>Descripción: {currentInvoice.setResponseDescription}</li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
        {currentInvoice.status === INVOICE_STATUS_SET_UNKNOWN_ERROR ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Error: {currentInvoice.setError}</li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
      </span>
    </td>
  );
};

export default InvoiceCrudStatus;
