interface Client {
  id?: number;
  code?: string;
  taxPayerId?: number;
  isTaxPayer?: boolean;
  ruc?: string;
  socialName?: string;
  fantasyName?: string;
  type?: string;
  countryCode?: string;
  address?: string;
  houseNumber?: number;
  department?: number;
  departmentDescription?: string;
  district?: number;
  districtDescription?: string;
  city?: number;
  cityDescription?: string;
  phoneNumber?: string;
  email?: string;
  hasAddress?: boolean;
}

// Client types
export const CLIENT_TYPE_PERSON = "person";
export const CLIENT_TYPE_PERSON_LABEL = "Persona";
export const CLIENT_TYPE_ENTITY = "entity";
export const CLIENT_TYPE_ENTITY_LABEL = "Entidad";
export const CLIENT_TYPE_FOREIGN_ENTITY = "foreign_entity";
export const CLIENT_TYPE_FOREIGN_ENTITY_LABEL = "Cliente Extranjero";
export const CLIENT_TYPE_NOT_NOMINATED = "not_nominated";
export const CLIENT_TYPE_NOT_NOMINATED_LABEL = "Sin Nombre";
export const CLIENT_TYPE_DIPLOMATIC = "diplomatic";
export const CLIENT_TYPE_DIPLOMATIC_LABEL = "Diplomático";
export const CLIENT_TYPE_GOVERNMENT = "government";
export const CLIENT_TYPE_GOVERNMENT_LABEL = "Gobierno";

export const CLIENT_TYPE_CHOICES: Map<string, string> = new Map([
  [CLIENT_TYPE_PERSON, CLIENT_TYPE_PERSON_LABEL],
  [CLIENT_TYPE_ENTITY, CLIENT_TYPE_ENTITY_LABEL],
  [CLIENT_TYPE_FOREIGN_ENTITY, CLIENT_TYPE_FOREIGN_ENTITY_LABEL],
  [CLIENT_TYPE_NOT_NOMINATED, CLIENT_TYPE_NOT_NOMINATED_LABEL],
  [CLIENT_TYPE_DIPLOMATIC, CLIENT_TYPE_DIPLOMATIC_LABEL],
  [CLIENT_TYPE_GOVERNMENT, CLIENT_TYPE_GOVERNMENT_LABEL],
]);

export const newClient = (taxPayerId?: number): Client => {
  return {
    taxPayerId: taxPayerId,
  };
};

export default Client;
