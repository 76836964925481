import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CTextarea,
} from "@coreui/react";
import React, { useCallback, useEffect, useState } from "react";
import Errors, { errorsAreSame, getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";
import {
  emptyValueOnUndefined,
  emptyValueOnUndefinedOrNull,
} from "../../utils/fields";
import { RemissionNoteItem } from "../../models/remission-note";
import Client, { CLIENT_TYPE_GOVERNMENT } from "../../models/client";
interface remissionNoteItemFormProps {
  item: RemissionNoteItem;
  client: Client | null;
  onDelete: (_: RemissionNoteItem) => void;
  onChange: (_: RemissionNoteItem) => void;
  errors: Errors;
}

const addMissingValues = (item: RemissionNoteItem) => {
  const newItem = { ...item };
  // TODO: see how to handle this
  newItem.measureUnit = 77;
  newItem.code = "001";

  return newItem;
};

const RemissionNoteItemForm: React.FC<remissionNoteItemFormProps> = ({
  item,
  client,
  onDelete,
  onChange,
  errors,
}) => {
  const [editingItem, setEditingItem] = useState(item);
  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newItem = { ...editingItem };
      if (!isNaN(parseInt(e.target.value))) {
        newItem.quantity = parseInt(e.target.value);
      } else {
        newItem.quantity = undefined;
      }

      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newItem = { ...editingItem };
      newItem.description = e.target.value;

      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onDncpLevelCodeGeneralChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newItem = { ...editingItem };
      newItem.dncpLevelCodeGeneral = e.target.value;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onDncpLevelCodeSpecificChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newItem = { ...editingItem };
      newItem.dncpLevelCodeSpecific = e.target.value;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  useEffect(() => {
    setEditingItem(item);
  }, [item]);

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Cantidad:
            </CLabel>
          </CCol>

          <CCol md={1}>
            <CInput
              type="number"
              value={emptyValueOnUndefined(editingItem.quantity)}
              onChange={onQuantityChange}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("quantity", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Detalle:
            </CLabel>
          </CCol>
          <CCol md={10}>
            <CTextarea
              placeholder="Detalle del ítem"
              value={emptyValueOnUndefined(editingItem.description)}
              onChange={onDescriptionChange}
            ></CTextarea>
            <FieldErrors
              errors={getFieldErrors("description", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      {client !== null && client.type === CLIENT_TYPE_GOVERNMENT ? (
        <>
          <CRow>
            <CCol>
              <h4>Datos DNCP</h4>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Nivel DNCP General:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="text"
                  placeholder="Ingrese el Nivel DNCP General"
                  onChange={onDncpLevelCodeGeneralChange}
                  value={emptyValueOnUndefinedOrNull(
                    editingItem.dncpLevelCodeGeneral
                  )}
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("dncpLevelCodeGeneral", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Nivel DNCP Específico:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="text"
                  placeholder="Ingrese la modalidad de Contratación"
                  onChange={onDncpLevelCodeSpecificChange}
                  value={emptyValueOnUndefinedOrNull(
                    editingItem.dncpLevelCodeSpecific
                  )}
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("dncpLevelCodeSpecific", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </>
      ) : (
        <></>
      )}
      <CRow className="mt-2">
        <CCol>
          <CButton
            className="btn btn-danger float-right"
            onClick={onDeleteClick}
          >
            <i className="fa fa-trash"></i>
          </CButton>
        </CCol>
      </CRow>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: remissionNoteItemFormProps,
  nextItemProps: remissionNoteItemFormProps
): boolean => {
  return (
    prevItemProps.item.id === nextItemProps.item.id &&
    prevItemProps.item.obs === nextItemProps.item.obs &&
    prevItemProps.item.measureUnit === nextItemProps.item.measureUnit &&
    prevItemProps.item.description === nextItemProps.item.description &&
    prevItemProps.item.quantity === nextItemProps.item.quantity &&
    errorsAreSame(prevItemProps.errors, nextItemProps.errors)
  );
};

export default React.memo(RemissionNoteItemForm, propsAreEqual);
