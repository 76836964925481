import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CTooltip,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import StampDeleteModal from "./StampDeleteModal";
import StampUpdateModal from "./StampUpdateModal";
import Stamp from "../../models/stamp";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import StampEmailSettingsModal from "./StampEmailSettingsModal";

const ITEMS_PER_PAGE = 10;

const StampList = () => {
  const fields = [
    { key: "identifier", _classes: ["text-center"], label: "Código" },
    { key: "beginDate", _classes: ["text-center"], label: "Descripción" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [showStampEditModal, setShowStampEditModal] = useState(false);
  const [showStampDeleteModal, setShowStampDeleteModal] = useState(false);
  const [showStampEmailSettingsModal, setShowStampEmailSettingsModal] =
    useState(false);
  const [stamp, setStamp] = useState<Stamp | undefined>(undefined);
  const [stampForEmailSettings, setStampForEmailSettings] = useState<
    Stamp | undefined
  >(undefined);

  const [loading, setLoading] = useState(true);
  const [stamps, setStamps] = useState<Stamp[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    return { page: page };
  };

  const fetchStamps = useCallback(async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const additionalParams = new Map<string, string>();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());
    const itemsRequestStatus = await getList<Stamp>(
      "/stamps/",
      limit,
      offset,
      additionalParams
    );

    if (itemsRequestStatus.status === SUCCESS) {
      if (itemsRequestStatus.data !== undefined) {
        const count = itemsRequestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setStamps(itemsRequestStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = itemsRequestStatus.detail
        ? itemsRequestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  /*const reset = () => {
    history.push(history.location.pathname);
  };*/

  const onAddClick = () => {
    setStamp(undefined);
    setShowStampEditModal(true);
  };

  const onUpdateClick = (stamp: Stamp) => {
    setStamp(stamp);
    setShowStampEditModal(true);
  };

  const onDeleteClick = (stamp: Stamp) => {
    setStamp(stamp);
    setShowStampDeleteModal(true);
  };

  const onStampEditCancel = () => {
    setShowStampEditModal(false);
  };

  const onStampEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowStampEditModal(false);
    setStamps([]);
    setLoading(true);
    //setTotalPages(1);
    //setCurrentPage(1);
    //reset();
    fetchStamps();
  };

  const onStampDeleteCancel = () => {
    setShowStampDeleteModal(false);
  };

  const onStampDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowStampDeleteModal(false);
    setStamps([]);
    setLoading(true);
    fetchStamps();
  };

  const onStampEmailSettingsClick = (newStampForEmailSettings: Stamp) => {
    setStampForEmailSettings(newStampForEmailSettings);
    setShowStampEmailSettingsModal(true);
  };

  const onStampEmailSettingsSuccess = () => {
    successAlert("Configuración de correo modificada con éxito");
    setShowStampEmailSettingsModal(false);
  };

  const onStampEmailSettingsCancel = () => {
    setShowStampEmailSettingsModal(false);
  };

  useEffect(() => {
    fetchStamps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("stamps")) {
        return;
      }
      fetchStamps();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setLoading(true);
    fetchStamps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Timbrados</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                  &nbsp; Agregar Nuevo
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={stamps}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    beginDate: (item: Stamp) => {
                      const srtDate = item.beginDate
                        ? item.beginDate.slice(0, -1)
                        : "-";
                      const renderDate =
                        srtDate === "-"
                          ? srtDate
                          : new Date(srtDate).toLocaleDateString();
                      return <td className="text-center">{renderDate}</td>;
                    },
                    actions: (item: Stamp) => {
                      const editButton = (
                        <CTooltip content="Editar">
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </CTooltip>
                      );

                      const deleteButton = (
                        <CTooltip content="Eliminar">
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </CTooltip>
                      );

                      const emailSettingsButton = (
                        <CTooltip content="Configuración de Correo">
                          <CButton
                            className="text-white"
                            color="info"
                            onClick={() => {
                              onStampEmailSettingsClick(item);
                            }}
                          >
                            <i className="fa fa-envelope"></i>
                          </CButton>
                        </CTooltip>
                      );

                      return (
                        <td className="text-center">
                          <CButtonGroup>
                            {emailSettingsButton}
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <StampUpdateModal
        show={showStampEditModal}
        onCancel={onStampEditCancel}
        onSuccess={onStampEditSuccess}
        stamp={stamp}
      />
      <StampDeleteModal
        show={showStampDeleteModal}
        onCancel={onStampDeleteCancel}
        onSuccess={onStampDeleteSuccess}
        stamp={stamp}
      />
      <StampEmailSettingsModal
        show={showStampEmailSettingsModal}
        onCancel={onStampEmailSettingsCancel}
        onSuccess={onStampEmailSettingsSuccess}
        stamp={stampForEmailSettings}
      />
    </>
  );
};

export default StampList;
