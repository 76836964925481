import { useSelector } from "react-redux";
import Stamp from "../../models/stamp";
import { RootState } from "../../store";
import { useCallback, useEffect, useState } from "react";
import Errors, { getFieldErrors } from "../../models/errors";
import StampEmailSettings from "../../models/stamp-email-settings";
import { createItem, getItem } from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { FieldErrors } from "../form/FieldErrors";
import { emptyValueOnUndefined } from "../../utils/fields";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import { Spinner } from "react-bootstrap";
import Loading from "../indicators/Loading";

interface StampEmailSettingsFormProps {
  stamp?: Stamp;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const StampEmailSettingsForm: React.FC<StampEmailSettingsFormProps> = ({
  stamp,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);

  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<StampEmailSettings>({});

  const onHostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => {
      return { ...prev, host: e.target.value };
    });
  };

  const onUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => {
      return { ...prev, user: e.target.value };
    });
  };

  const onPasswordchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => {
      return { ...prev, password: e.target.value };
    });
  };

  const onFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => {
      return { ...prev, from: e.target.value };
    });
  };

  const onPortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => {
      let newPort: number | undefined = undefined;
      if (!isNaN(parseInt(e.target.value))) {
        newPort = parseInt(e.target.value);
      }
      return { ...prev, port: newPort };
    });
  };

  const onUseSslChange = (newUseSsl: boolean) => {
    setSettings((prev) => {
      return { ...prev, useSsl: newUseSsl };
    });
  };

  const onSendEnableChange = (newSendEnable: boolean) => {
    setSettings((prev) => {
      return { ...prev, sendEnable: newSendEnable };
    });
  };

  const onSave = useCallback(async () => {
    if (stamp === undefined) {
      return;
    }
    const newSettings = { ...settings, taxPayerId: taxPayerId };
    newSettings.stampId = stamp.id;

    const requestStatus = await createItem(
      `/stamps/${stamp.id}/email_settings/update/`,
      newSettings
    );

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stamp, settings]);

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setSettings({});
  };

  const loadStampEmailSettings = async (newStamp?: Stamp) => {
    setLoading(true);
    if (newStamp === undefined || newStamp.id === undefined) {
      setSettings({});
      setLoading(false);
      return;
    }

    const requestStatus = await getItem<StampEmailSettings>(
      `/stamps/${newStamp.id}/email_settings/`
    );

    if (
      requestStatus.status !== SUCCESS &&
      requestStatus.status !== NOT_FOUND
    ) {
      let message = "Ha ocurrido un error";
      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }

      warningAlert(message);

      setSettings({});
      setLoading(false);
      return;
    }

    if (
      requestStatus.status === NOT_FOUND ||
      requestStatus.data === undefined
    ) {
      setSettings({});
      setLoading(false);
      return;
    }

    setSettings(requestStatus.data);
    setLoading(false);
  };

  useEffect(() => {
    loadStampEmailSettings(stamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stamp]);

  if (loading) {
    return <Loading height="30vh" />;
  }

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Host:
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                onChange={onHostChange}
                placeholder="Host"
                value={emptyValueOnUndefined(settings.host)}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("host", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Puerto:
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                onChange={onPortChange}
                placeholder="Puerto"
                value={emptyValueOnUndefined(settings.port)}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("port", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Usuario:
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                onChange={onUserChange}
                placeholder="Usuario"
                value={emptyValueOnUndefined(settings.user)}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("user", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Contraseña:
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                onChange={onPasswordchange}
                placeholder="Contraseña"
                value={emptyValueOnUndefined(settings.password)}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("password", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Correo desde:
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                onChange={onFromChange}
                placeholder="Correo desde"
                value={emptyValueOnUndefined(settings.from)}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("from", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> SSL:
              </CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  value={settings.useSsl === true}
                  onChange={onUseSslChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("useSsl", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Habilitar:
              </CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  value={settings.sendEnable === true}
                  onChange={onSendEnableChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("sendEnable", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default StampEmailSettingsForm;
