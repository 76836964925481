import { useSelector } from "react-redux";
import { RootState } from "../../store";
import React, { useEffect, useRef, useState } from "react";
import Errors, {
  extractArrayErrors,
  getFieldErrors,
} from "../../models/errors";
import Client, { CLIENT_TYPE_GOVERNMENT } from "../../models/client";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "../utils/messages";
import Establishment from "../../models/establishment";
import {
  ItemRequestStatus,
  createItem,
  getItem,
  updateItem,
} from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";

import "react-datetime/css/react-datetime.css";
import moment from "moment";

import EstablishmentSelect from "../establishments/EstablishmentSelect";
import { FieldErrors } from "../form/FieldErrors";
import ClientSelect from "../client/ClientSelect";
import Loading from "../indicators/Loading";
import ClientUpdateModal from "../client/ClientUpdateModal";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import { CURRENCY_CHOICES, PYG } from "../../currency/available-currencies";
import CurrencyField from "../currencies/CurrencyField";
import CreditNoteItemForm from "./CreditNoteItemForm";
import { Spinner } from "react-bootstrap";
import { getRoutePath } from "../../routes";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import {
  CREDIT_NOTE_STATUS_APPROVED_BY_SET,
  CREDIT_NOTE_STATUS_UPLOADED_TO_SET,
  CreditNote,
  CreditNoteItem,
  newCreditNote,
  newCreditNoteItem,
} from "../../models/credit-note";
import {
  ASSOCIATED_DOCUMENT_TYPE_CHOICES,
  ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE,
  ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE,
} from "../../models/associated-document";
import { INVOICE_STATUS_APPROVED_BY_SET, Invoice } from "../../models/invoice";
import InvoiceSelect from "../invoice/InvoiceSelect";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";
import { getClientById } from "../../api/client";
import NotFound from "../indicators/NotFound";
import ServerError from "../indicators/ServerError";
import { getInvoiceById } from "../../api/invoice";

import Datetime from "react-datetime";

import { changeTZToUtc } from "../../utils/dates";
import PYGField from "../currencies/PYGField";

const CreditNoteForm = () => {
  const name = "Agregar Nota de Crédito";

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadError, setLoadError] = useState(false);
  const [loadNotFound, setLoadNotFound] = useState(false);

  const creditNoteRef = useRef<CreditNote | null>(newCreditNote(taxPayer.id));
  const [creditNoteDate, setCreditNoteDate] = useState<Date | undefined>(
    undefined
  );

  const [associatedDocumentType, setAssociatedDocumentType] = useState<
    string | undefined
  >(undefined);

  const [electronicInvoice, setElectronicInvoice] = useState<Invoice | null>(
    null
  );

  const [currency, setCurrency] = useState(PYG);
  const [pygExchangeRate, setPygExchangeRate] = useState<number | undefined>(
    undefined
  );
  const [totalAmount, setTotalAmount] = useState<number | undefined>(undefined);
  const [amount10Percent, setAmount10Percent] = useState<number | undefined>(
    undefined
  );
  const [amount5Percent, setAmount5Percent] = useState<number | undefined>(
    undefined
  );
  const [amountExempt, setAmountExempt] = useState<number | undefined>(
    undefined
  );
  const [iva10Percent, setIva10Percent] = useState<number | undefined>(
    undefined
  );
  const [iva5Percent, setIva5Percent] = useState<number | undefined>(undefined);

  /*const updateCreditNoteRef = (creditNote: CreditNote) => {
    creditNoteRef.current = creditNote;
  };*/

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [client, setClient] = useState<Client | null>(null);
  const [showAddClient, setShowAddClient] = useState(false);

  const [items, setItems] = useState<CreditNoteItem[]>([]);

  const history = useHistory();

  const getEditingCreditNoteId = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const editingCreditNoteId = urlParams.get("id");

    if (editingCreditNoteId !== null) {
      if (!isNaN(parseInt(editingCreditNoteId))) {
        return parseInt(editingCreditNoteId);
      }
    }

    return undefined;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.establishmentId = newEstablishment?.id;
    creditNoteRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dispatchPointId = newDispatchPoint?.id;
    creditNoteRef.current.dispatchPointIdentifier =
      newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.stampId = newStamp?.id;
    creditNoteRef.current.stampIdentifier = newStamp?.identifier;
    creditNoteRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onClientChange = (newClient: Client | null) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.clientId = newClient?.id;
    creditNoteRef.current.clientCode = newClient?.code;
    creditNoteRef.current.clientIsTaxPayer = newClient?.isTaxPayer;
    creditNoteRef.current.clientRuc = newClient?.ruc;
    creditNoteRef.current.clientSocialName = newClient?.socialName;
    creditNoteRef.current.clientFantasyName = newClient?.fantasyName;
    creditNoteRef.current.clientType = newClient?.type;
    creditNoteRef.current.clientCountryCode = newClient?.countryCode;
    creditNoteRef.current.clientHasAddress = newClient?.hasAddress;
    creditNoteRef.current.clientAddress = newClient?.address;
    creditNoteRef.current.clientHouseNumber = newClient?.houseNumber;
    creditNoteRef.current.clientDepartment = newClient?.department;
    creditNoteRef.current.clientDepartmentDescription =
      newClient?.departmentDescription;
    creditNoteRef.current.clientDistrict = newClient?.district;
    creditNoteRef.current.clientDistrictDescription =
      newClient?.districtDescription;
    creditNoteRef.current.clientCity = newClient?.city;
    creditNoteRef.current.clientCityDescription = newClient?.cityDescription;
    creditNoteRef.current.clientPhoneNumber = newClient?.phoneNumber;
    creditNoteRef.current.clientEmail = newClient?.email;

    setClient(newClient);
  };

  const onCreditNoteDateChange = (value: string | moment.Moment) => {
    if (creditNoteRef.current === null) {
      return;
    }

    const newDate = value as moment.Moment;

    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const creditNoteDate = new Date((value as moment.Moment).toISOString());
    setCreditNoteDate(creditNoteDate);

    creditNoteRef.current.creditNoteDate = creditNoteDate.toISOString();
  };

  const onAssociatedDocumentTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    const newAssociatedDocumentType = e.target.value;

    creditNoteRef.current.associatedDocumentType = newAssociatedDocumentType;

    if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE
    ) {
      creditNoteRef.current.associatedDocumentPrintedType = undefined;
      creditNoteRef.current.associatedDocumentStampIdentifier = undefined;
      creditNoteRef.current.associatedDocumentEstablishmentCode = undefined;
      creditNoteRef.current.associatedDocumentDispatchPointCode = undefined;
      creditNoteRef.current.associatedDocumentNumber = undefined;
      creditNoteRef.current.associatedDocumentDate = undefined;
    } else if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE
    ) {
      creditNoteRef.current.associatedDocumentCdc = undefined;
      creditNoteRef.current.invoiceId = undefined;
      creditNoteRef.current.associatedDocumentPrintedType =
        ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;
      setElectronicInvoice(null);
    }

    setAssociatedDocumentType(e.target.value);
  };

  const onElectronicInvoiceChange = (newElectronicInvoice: Invoice | null) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.invoiceId = newElectronicInvoice?.id;
    creditNoteRef.current.associatedDocumentCdc = newElectronicInvoice?.cdc;

    setElectronicInvoice(newElectronicInvoice);
  };

  const onAssociatedDocumentStampIdentifierChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.associatedDocumentStampIdentifier = e.target.value;
  };

  const onAssociatedDocumentEstablishmentCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.associatedDocumentEstablishmentCode = e.target.value;
  };

  const onAssociatedDocumentDispatchPointCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.associatedDocumentDispatchPointCode = e.target.value;
  };

  const onAssociatedDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.associatedDocumentNumber = e.target.value;
  };

  const onAssociatedDocumentDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.associatedDocumentDate = e.target.value;
  };

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.currency = e.target.value;
    setCurrency(e.target.value);
  };

  const onPygExchangeRateChange = (newExchangeRate?: number) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.pygExchangeRate = newExchangeRate;
    setPygExchangeRate(newExchangeRate);
  };

  const onDncpContractCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dncpContractCode = e.target.value;
  };

  const onDncpContractModalityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dncpContractModality = e.target.value;
  };

  const onDncpContractEntityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dncpContractEntity = e.target.value;
  };

  const onDncpContractYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dncpContractYear = e.target.value;
  };

  const onDncpContractSequenceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dncpContractSequence = e.target.value;
  };

  const onDncpContractDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.dncpContractDate = e.target.value;
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (creditNoteRef.current === null) {
      return;
    }

    creditNoteRef.current.obs = e.target.value;
  };

  const onAddClientClick = () => {
    setShowAddClient(true);
  };

  const onAddClientSuccess = (newClient?: Client) => {
    let message = "Cliente agregado correctamente";
    onClientChange(newClient ? newClient : null);
    if (newClient) {
      message = `Cliente ${newClient.socialName} agregado correctamente`;
    }
    successAlert(message);
    setShowAddClient(false);
  };

  const onAddClientCancel = () => {
    setShowAddClient(false);
  };

  const onSave = async () => {
    if (creditNoteRef.current === null) {
      return;
    }

    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<CreditNote>>;
    let successMessage = "Nota de Crédito agregada exitósamente";

    const creditNote = creditNoteRef.current;
    creditNote.taxPayerRuc = taxPayer.ruc;
    creditNote.taxPayerSocialName = taxPayer.socialName;
    creditNote.taxPayerFantasyName = taxPayer.fantasyName;

    if (creditNote.associatedDocumentType === undefined) {
      creditNote.associatedDocumentType =
        ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;
    }

    if (creditNote.currency === undefined) {
      creditNote.currency = PYG;
    }

    if (creditNote.id === undefined) {
      requestPromise = createItem<CreditNote>(
        "/credit_notes/create/",
        creditNote
      );
    } else {
      requestPromise = updateItem<CreditNote>(
        `/credit_notes/${creditNote.id}/`,
        creditNote
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";

      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      successAlert(successMessage);
      history.push(getRoutePath("creditNotes"));
    }
  };

  const loadInitialCreditNoteById = async (): Promise<CreditNote | null> => {
    const editingId = getEditingCreditNoteId();

    if (editingId === undefined) {
      return null;
    }

    let requestStatus = await getItem<CreditNote>(
      `/credit_notes/${editingId}/`
    );

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.status === NOT_FOUND) {
        setLoadNotFound(true);
      } else {
        setLoadError(true);
      }

      return null;
    }

    if (requestStatus.data === undefined || requestStatus.data === null) {
      setLoadError(true);
      return null;
    }

    return requestStatus.data!;
  };

  const loadInitialData = async () => {
    setLoading(true);
    const creditNote = await loadInitialCreditNoteById();

    if (creditNote === null) {
      setLoading(false);
      return;
    }

    if (
      creditNote.status === CREDIT_NOTE_STATUS_UPLOADED_TO_SET ||
      creditNote.status === CREDIT_NOTE_STATUS_APPROVED_BY_SET
    ) {
      setLoading(false);
      setLoadError(true);
    }

    const stampPromise = getStampById(
      creditNote.stampId,
      creditNote.taxPayerId
    );
    const establishmentPromise = getEstablishmentById(
      creditNote.establishmentId,
      creditNote.taxPayerId
    );
    const dispatchPointPromise = getDispatchPointById(
      creditNote.dispatchPointId,
      creditNote.taxPayerId
    );
    const clientPromise = getClientById(
      creditNote.clientId,
      creditNote.taxPayerId
    );

    let invoicePromise: Promise<Invoice | null> | null = null;

    if (creditNote.invoiceId !== undefined) {
      invoicePromise = getInvoiceById(
        creditNote.invoiceId,
        creditNote.taxPayerId
      );
    }

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;
    const client = await clientPromise;

    creditNoteRef.current = creditNote;

    setCreditNoteDate(new Date(creditNote.creditNoteDate!));

    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);
    setClient(client);

    if (invoicePromise !== null) {
      const previousInvoice = await invoicePromise;
      setElectronicInvoice(previousInvoice);
    }

    setAssociatedDocumentType(creditNote.associatedDocumentType);

    onItemsChange(creditNote.items);

    setCurrency(creditNote.currency ? creditNote.currency : PYG);
    setPygExchangeRate(creditNote.pygExchangeRate);

    setLoading(false);
  };

  useEffect(() => {
    const newCreditNoteDate = changeTZToUtc(new Date());
    if (creditNoteRef.current !== null) {
      creditNoteRef.current.creditNoteDate = newCreditNoteDate.toISOString();
      setCreditNoteDate(newCreditNoteDate);
    }
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddItemClick = () => {
    if (creditNoteRef.current === null) {
      return;
    }

    const newItems = [...creditNoteRef.current.items, newCreditNoteItem()];
    onItemsChange(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onItemDelete = (index: number) => {
    if (creditNoteRef.current === null) {
      return;
    }

    const newItems = [...creditNoteRef.current.items];
    newItems.splice(index, 1);

    onItemsChange(newItems);
  };

  const onItemChange = (index: number, item: CreditNoteItem) => {
    if (creditNoteRef.current === null) {
      return;
    }

    const newItems = [...creditNoteRef.current.items];
    newItems[index] = item;
    onItemsChange(newItems);
  };

  const onItemsChange = (newItems: CreditNoteItem[]) => {
    if (creditNoteRef.current === null) {
      return;
    }

    let newTotalAmount: number | undefined = undefined;
    let newAmount10Percent: number | undefined = undefined;
    let newAmount5Percent: number | undefined = undefined;
    let newAmountExempt: number | undefined = undefined;
    let newTaxed10Percent: number | undefined = undefined;
    let newTaxed5Percent: number | undefined = undefined;
    let newIva10Percent: number | undefined = undefined;
    let newIva5Percent: number | undefined = undefined;

    for (let item of newItems) {
      if (item.unitAmount !== undefined && item.quantity !== undefined) {
        newTotalAmount = newTotalAmount
          ? newTotalAmount + item.unitAmount * item.quantity
          : item.unitAmount * item.quantity;
      }

      if (item.amount10Percent !== undefined) {
        newAmount10Percent = newAmount10Percent
          ? newAmount10Percent + item.amount10Percent
          : item.amount10Percent;
      }

      if (item.amount5Percent !== undefined) {
        newAmount5Percent = newAmount5Percent
          ? newAmount5Percent + item.amount5Percent
          : item.amount5Percent;
      }

      if (item.amountExempt !== undefined) {
        newAmountExempt = newAmountExempt
          ? newAmountExempt + item.amountExempt
          : item.amountExempt;
      }

      if (item.taxed10Percent !== undefined) {
        newTaxed10Percent = newTaxed10Percent
          ? newTaxed10Percent + item.taxed10Percent
          : item.taxed10Percent;
      }

      if (item.taxed5Percent !== undefined) {
        newTaxed5Percent = newTaxed5Percent
          ? newTaxed5Percent + item.taxed5Percent
          : item.taxed5Percent;
      }

      if (item.iva10Percent !== undefined) {
        newIva10Percent = newIva10Percent
          ? newIva10Percent + item.iva10Percent
          : item.iva10Percent;
      }

      if (item.iva5Percent !== undefined) {
        newIva5Percent = newIva5Percent
          ? newIva5Percent + item.iva5Percent
          : item.iva5Percent;
      }
    }

    setTotalAmount(newTotalAmount);
    setAmount10Percent(newAmount10Percent);
    setAmount5Percent(newAmount5Percent);
    setAmountExempt(newAmountExempt);
    setIva10Percent(newIva10Percent);
    setIva5Percent(newIva5Percent);

    creditNoteRef.current = {
      ...creditNoteRef.current,
      items: newItems,
      amount: newTotalAmount,
      amount10Percent: newAmount10Percent,
      amount5Percent: newAmount5Percent,
      amountExempt: newAmountExempt,
      taxed10Percent: newTaxed10Percent,
      taxed5Percent: newTaxed5Percent,
      iva10Percent: newIva10Percent,
      iva5Percent: newIva5Percent,
    };

    setItems(newItems);
  };

  const showElectronicInvoiceSelect =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;
  const showPrintedInvoiceFields =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;

  const itemErrors = extractArrayErrors("items", errors);

  if (loading) {
    return <Loading />;
  }

  if (loadNotFound) {
    return <NotFound />;
  }

  if (loadError) {
    return <ServerError />;
  }

  return (
    <>
      <CCard>
        <CCardHeader>{name}</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Timbrado:
                </CLabel>
              </CCol>
              <CCol>
                <StampSelect onChange={onStampChange} value={stamp} />
                <FieldErrors
                  errors={getFieldErrors("stampId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Establecimiento:
                </CLabel>
              </CCol>
              <CCol>
                <EstablishmentSelect
                  onChange={onEstablishmentChange}
                  value={establishment}
                />
                <FieldErrors
                  errors={getFieldErrors("establishmentId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Punto de Expedición:
                </CLabel>
              </CCol>
              <CCol>
                <DispatchPointSelect
                  onChange={onDispatchPointChange}
                  value={dispatchPoint}
                />
                <FieldErrors
                  errors={getFieldErrors("dispatchPointId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Cliente:
                </CLabel>
              </CCol>
              <CCol>
                <CInputGroup>
                  <ClientSelect
                    value={client}
                    onChange={onClientChange}
                  ></ClientSelect>
                  <CInputGroupAppend>
                    <CButton
                      className="btn btn-primary"
                      // Prevent button on top
                      style={{ zIndex: 0 }}
                      onClick={onAddClientClick}
                    >
                      <i className="fa fa-plus"></i>
                    </CButton>
                  </CInputGroupAppend>
                </CInputGroup>
                <FieldErrors
                  errors={getFieldErrors("clientId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Fecha de emisión:
                </CLabel>
              </CCol>
              <CCol>
                <Datetime
                  className="pl-0"
                  onChange={onCreditNoteDateChange}
                  value={creditNoteDate}
                  locale="es/PY"
                  utc
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                ></Datetime>
                <FieldErrors
                  errors={getFieldErrors("creditNoteDate", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          {client !== null && client.type === CLIENT_TYPE_GOVERNMENT ? (
            <>
              <CRow>
                <CCol>
                  <h4>Datos de Contratación DNCP</h4>
                </CCol>
              </CRow>
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Código de
                      Contratación:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      placeholder="Ingrese el Código de Contratación"
                      onChange={onDncpContractCodeChange}
                      defaultValue={
                        creditNoteRef.current !== null &&
                        creditNoteRef.current.dncpContractCode
                          ? creditNoteRef.current.dncpContractCode
                          : ""
                      }
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors("dncpContractCode", errors) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Modalidad de
                      Contratación:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      placeholder="Ingrese la modalidad de Contratación"
                      onChange={onDncpContractModalityChange}
                      defaultValue={
                        creditNoteRef.current !== null &&
                        creditNoteRef.current.dncpContractModality
                          ? creditNoteRef.current.dncpContractModality
                          : ""
                      }
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "dncpContractModality",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Entidad de
                      Contratación:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      placeholder="Ingrese la entidad de Contratación"
                      onChange={onDncpContractEntityChange}
                      defaultValue={
                        creditNoteRef.current !== null &&
                        creditNoteRef.current.dncpContractEntity
                          ? creditNoteRef.current.dncpContractEntity
                          : ""
                      }
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors("dncpContractEntity", errors) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Año de
                      Contratación:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      placeholder="Ingrese el año de Contratación"
                      onChange={onDncpContractYearChange}
                      defaultValue={
                        creditNoteRef.current !== null &&
                        creditNoteRef.current.dncpContractYear
                          ? creditNoteRef.current.dncpContractYear
                          : ""
                      }
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors("dncpContractYear", errors) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Secuencia de
                      Contratación:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      placeholder="Ingrese la secuencia de Contratación"
                      onChange={onDncpContractSequenceChange}
                      defaultValue={
                        creditNoteRef.current !== null &&
                        creditNoteRef.current.dncpContractSequence
                          ? creditNoteRef.current.dncpContractSequence
                          : ""
                      }
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "dncpContractSequence",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Fecha de
                      Contratación:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="date"
                      placeholder="Ingrese la fecha de Contratación"
                      onChange={onDncpContractDateChange}
                      defaultValue={
                        creditNoteRef.current !== null &&
                        creditNoteRef.current.dncpContractDate
                          ? creditNoteRef.current.dncpContractDate
                          : ""
                      }
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors("dncpContractDate", errors) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            </>
          ) : (
            <></>
          )}
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Tipo Documento
                  Asociado:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    creditNoteRef.current
                      ? creditNoteRef.current.associatedDocumentType
                      : undefined
                  }
                  onChange={onAssociatedDocumentTypeChange}
                >
                  <option value={""}>-----</option>
                  {Array.from(ASSOCIATED_DOCUMENT_TYPE_CHOICES.entries()).map(
                    (entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    }
                  )}
                </CSelect>
                <FieldErrors
                  errors={
                    getFieldErrors("associatedDocumentType", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          {showElectronicInvoiceSelect ? (
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Factura Electrónica:
                  </CLabel>
                </CCol>
                <CCol>
                  <InvoiceSelect
                    onChange={onElectronicInvoiceChange}
                    value={electronicInvoice}
                    status={INVOICE_STATUS_APPROVED_BY_SET}
                  />
                  <FieldErrors
                    errors={getFieldErrors("invoiceId", errors) as string[]}
                  ></FieldErrors>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "associatedDocumentCdc",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          ) : (
            <></>
          )}
          {showPrintedInvoiceFields ? (
            <>
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Timbrado:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        creditNoteRef.current
                          ? creditNoteRef.current
                              .associatedDocumentStampIdentifier
                          : undefined
                      }
                      onChange={onAssociatedDocumentStampIdentifierChange}
                      placeholder="Timbrado"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentStampIdentifier",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Establecimiento:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        creditNoteRef.current
                          ? creditNoteRef.current
                              .associatedDocumentEstablishmentCode
                          : undefined
                      }
                      onChange={onAssociatedDocumentEstablishmentCodeChange}
                      placeholder="Cod"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentEstablishmentCode",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Punto Expedición:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        creditNoteRef.current
                          ? creditNoteRef.current
                              .associatedDocumentDispatchPointCode
                          : undefined
                      }
                      onChange={onAssociatedDocumentDispatchPointCodeChange}
                      placeholder="Cod"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentDispatchPointCode",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Nro. Factura:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="text"
                      defaultValue={
                        creditNoteRef.current
                          ? creditNoteRef.current.associatedDocumentNumber
                          : undefined
                      }
                      onChange={onAssociatedDocumentNumberChange}
                      placeholder="Nro"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentNumber",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Fecha Factura:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CInput
                      type="date"
                      defaultValue={
                        creditNoteRef.current
                          ? creditNoteRef.current.associatedDocumentDate
                          : undefined
                      }
                      onChange={onAssociatedDocumentDateChange}
                      placeholder="Fecha"
                    ></CInput>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentDate",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            </>
          ) : (
            <></>
          )}
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Moneda:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    creditNoteRef.current !== null
                      ? creditNoteRef.current.currency
                      : PYG
                  }
                  onChange={onCurrencyChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(CURRENCY_CHOICES.entries()).map((entry) => {
                    return (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    );
                  })}
                </CSelect>
                <FieldErrors
                  errors={getFieldErrors("currency", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          {currency !== PYG ? (
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Cambio:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <PYGField
                    placeholder="Total"
                    limit={1000000000000}
                    onChange={onPygExchangeRateChange}
                    value={pygExchangeRate}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("pygExchangeRate", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          ) : (
            <></>
          )}

          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Obs:</CLabel>
              </CCol>
              <CCol md={10}>
                <CTextarea
                  placeholder="Info Adicional"
                  defaultValue={
                    creditNoteRef.current !== null
                      ? creditNoteRef.current.obs
                      : undefined
                  }
                  onChange={onObsChange}
                ></CTextarea>
                <FieldErrors
                  errors={getFieldErrors("obs", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>

          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Total:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total"
                  limit={1000000000000}
                  disabled={true}
                  value={totalAmount}
                />
                <FieldErrors
                  errors={getFieldErrors("amount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Total 10%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total 10%"
                  limit={1000000000000}
                  disabled={true}
                  value={amount10Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("amount10Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>Total 5%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total 5%"
                  limit={1000000000000}
                  disabled={true}
                  value={amount5Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("amount5Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>Total Exento:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total Exento"
                  limit={1000000000000}
                  disabled={true}
                  value={amountExempt}
                />
                <FieldErrors
                  errors={getFieldErrors("amountExempt", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>IVA 10%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="IVA 10%"
                  limit={1000000000000}
                  disabled={true}
                  value={iva10Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("iva10Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>IVA 5%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="IVA 5%"
                  limit={1000000000000}
                  disabled={true}
                  value={iva5Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("iva5Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol>
              <h4>Ítems</h4>
            </CCol>
          </CRow>
          {items.map((item, ix) => (
            <CRow key={ix}>
              <CCol>
                <CreditNoteItemForm
                  currency={currency}
                  item={item}
                  client={client}
                  onDelete={() => onItemDelete(ix)}
                  onChange={(item) => onItemChange(ix, item)}
                  errors={ix < itemErrors.length ? itemErrors[ix] : {}}
                />
              </CCol>
            </CRow>
          ))}
          <CRow>
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
              >
                Agregar Ítem
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" color="primary" onClick={onSave}>
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Guardando..." : "Guardar"}
          </CButton>
        </CCardFooter>
      </CCard>
      <ClientUpdateModal
        show={showAddClient}
        onSuccess={onAddClientSuccess}
        onCancel={onAddClientCancel}
      />
    </>
  );
};

export default CreditNoteForm;
